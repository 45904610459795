import * as React from "react";

export function UploadIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg {...props} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74984 43.1666C5.72886 43.1666 7.33317 44.7709 7.33317 46.75V61.0833C7.33317 62.0337 7.7107 62.9451 8.3827 63.6171C9.05471 64.2891 9.96615 64.6666 10.9165 64.6666H61.0832C62.0335 64.6666 62.945 64.2891 63.617 63.6171C64.289 62.9451 64.6665 62.0337 64.6665 61.0833V46.75C64.6665 44.7709 66.2708 43.1666 68.2498 43.1666C70.2289 43.1666 71.8332 44.7709 71.8332 46.75V61.0833C71.8332 63.9344 70.7006 66.6687 68.6846 68.6847C66.6686 70.7007 63.9343 71.8333 61.0832 71.8333H10.9165C8.06542 71.8333 5.33112 70.7007 3.31511 68.6847C1.29909 66.6687 0.166504 63.9344 0.166504 61.0833V46.75C0.166504 44.7709 1.77082 43.1666 3.74984 43.1666Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74984 43.1666C5.72886 43.1666 7.33317 44.7709 7.33317 46.75V61.0833C7.33317 62.0337 7.7107 62.9451 8.3827 63.6171C9.05471 64.2891 9.96615 64.6666 10.9165 64.6666H61.0832C62.0335 64.6666 62.945 64.2891 63.617 63.6171C64.289 62.9451 64.6665 62.0337 64.6665 61.0833V46.75C64.6665 44.7709 66.2708 43.1666 68.2498 43.1666C70.2289 43.1666 71.8332 44.7709 71.8332 46.75V61.0833C71.8332 63.9344 70.7006 66.6687 68.6846 68.6847C66.6686 70.7007 63.9343 71.8333 61.0832 71.8333H10.9165C8.06542 71.8333 5.33112 70.7007 3.31511 68.6847C1.29909 66.6687 0.166504 63.9344 0.166504 61.0833V46.75C0.166504 44.7709 1.77082 43.1666 3.74984 43.1666Z"
        fill="url(#paint0_linear_781_281)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.466 1.21616C34.8654 -0.183219 37.1343 -0.183219 38.5336 1.21616L56.4503 19.1328C57.8497 20.5322 57.8497 22.801 56.4503 24.2004C55.0509 25.5998 52.7821 25.5998 51.3827 24.2004L35.9998 8.81756L20.617 24.2004C19.2176 25.5998 16.9488 25.5998 15.5494 24.2004C14.15 22.801 14.15 20.5322 15.5494 19.1328L33.466 1.21616Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.466 1.21616C34.8654 -0.183219 37.1343 -0.183219 38.5336 1.21616L56.4503 19.1328C57.8497 20.5322 57.8497 22.801 56.4503 24.2004C55.0509 25.5998 52.7821 25.5998 51.3827 24.2004L35.9998 8.81756L20.617 24.2004C19.2176 25.5998 16.9488 25.5998 15.5494 24.2004C14.15 22.801 14.15 20.5322 15.5494 19.1328L33.466 1.21616Z"
        fill="url(#paint1_linear_781_281)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9998 0.166626C37.9789 0.166626 39.5832 1.77094 39.5832 3.74996V46.75C39.5832 48.729 37.9789 50.3333 35.9998 50.3333C34.0208 50.3333 32.4165 48.729 32.4165 46.75V3.74996C32.4165 1.77094 34.0208 0.166626 35.9998 0.166626Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9998 0.166626C37.9789 0.166626 39.5832 1.77094 39.5832 3.74996V46.75C39.5832 48.729 37.9789 50.3333 35.9998 50.3333C34.0208 50.3333 32.4165 48.729 32.4165 46.75V3.74996C32.4165 1.77094 34.0208 0.166626 35.9998 0.166626Z"
        fill="url(#paint2_linear_781_281)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_781_281"
          x1="-16.8607"
          y1="-43.4926"
          x2="75.0727"
          y2="-48.0252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_781_281"
          x1="-16.8607"
          y1="-43.4926"
          x2="75.0727"
          y2="-48.0252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_781_281"
          x1="-16.8607"
          y1="-43.4926"
          x2="75.0727"
          y2="-48.0252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
