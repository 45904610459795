import * as React from "react";

export function Logo(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg {...props} viewBox="0 0 419 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.96 119C35.8933 119 27.96 116.507 21.16 111.52C14.4733 106.533 9.31667 99.45 5.69 90.27C2.06333 81.09 0.25 70.2667 0.25 57.8C0.25 50.5467 1.44 42.7833 3.82 34.51C6.31333 26.2367 9.82667 19.2667 14.36 13.6C19.0067 7.82001 24.39 4.93001 30.51 4.93001C35.4967 4.93001 39.6333 6.74334 42.92 10.37C46.2067 13.9967 47.85 18.4733 47.85 23.8C47.85 25.3867 47.4533 27.54 46.66 30.26C45.98 32.8667 45.1867 35.5867 44.28 38.42C42.58 43.2933 41.2767 47.6 40.37 51.34C39.4633 54.9667 39.01 58.48 39.01 61.88C39.01 66.8667 39.7467 70.4933 41.22 72.76C42.6933 75.0267 44.5067 76.16 46.66 76.16C49.4933 76.16 51.4767 74.7433 52.61 71.91C53.8567 68.9633 54.48 63.58 54.48 55.76L54.31 44.03C54.31 34.9633 55.5 27.7667 57.88 22.44C60.26 17 65.02 14.28 72.16 14.28C77.4867 14.28 81.51 15.7533 84.23 18.7C86.95 21.6467 88.7067 25.3867 89.5 29.92C90.2933 34.34 90.69 39.7233 90.69 46.07V54.74C90.69 63.0133 91.37 68.9067 92.73 72.42C94.2033 75.82 96.5267 77.52 99.7 77.52C101.853 77.52 103.497 76.4433 104.63 74.29C105.877 72.0233 106.5 68.68 106.5 64.26C106.5 60.9733 106.103 57.6867 105.31 54.4C104.517 51.1133 103.27 46.92 101.57 41.82C100.097 36.9467 99.02 33.32 98.34 30.94C97.7733 28.4467 97.49 26.0667 97.49 23.8C97.49 18.4733 99.19 14.0533 102.59 10.54C106.103 7.02667 110.183 5.27 114.83 5.27C121.517 5.27 127.127 8.10334 131.66 13.77C136.307 19.3233 139.763 26.18 142.03 34.34C144.297 42.5 145.43 50.32 145.43 57.8C145.43 70.2667 143.617 81.09 139.99 90.27C136.363 99.45 131.15 106.533 124.35 111.52C117.663 116.393 109.787 118.83 100.72 118.83C95.7333 118.83 90.86 117.697 86.1 115.43C81.34 113.163 76.92 109.763 72.84 105.23C68.76 109.763 64.2833 113.22 59.41 115.6C54.65 117.867 49.8333 119 44.96 119Z"
        fill="white"
      />
      <path
        d="M44.96 119C35.8933 119 27.96 116.507 21.16 111.52C14.4733 106.533 9.31667 99.45 5.69 90.27C2.06333 81.09 0.25 70.2667 0.25 57.8C0.25 50.5467 1.44 42.7833 3.82 34.51C6.31333 26.2367 9.82667 19.2667 14.36 13.6C19.0067 7.82001 24.39 4.93001 30.51 4.93001C35.4967 4.93001 39.6333 6.74334 42.92 10.37C46.2067 13.9967 47.85 18.4733 47.85 23.8C47.85 25.3867 47.4533 27.54 46.66 30.26C45.98 32.8667 45.1867 35.5867 44.28 38.42C42.58 43.2933 41.2767 47.6 40.37 51.34C39.4633 54.9667 39.01 58.48 39.01 61.88C39.01 66.8667 39.7467 70.4933 41.22 72.76C42.6933 75.0267 44.5067 76.16 46.66 76.16C49.4933 76.16 51.4767 74.7433 52.61 71.91C53.8567 68.9633 54.48 63.58 54.48 55.76L54.31 44.03C54.31 34.9633 55.5 27.7667 57.88 22.44C60.26 17 65.02 14.28 72.16 14.28C77.4867 14.28 81.51 15.7533 84.23 18.7C86.95 21.6467 88.7067 25.3867 89.5 29.92C90.2933 34.34 90.69 39.7233 90.69 46.07V54.74C90.69 63.0133 91.37 68.9067 92.73 72.42C94.2033 75.82 96.5267 77.52 99.7 77.52C101.853 77.52 103.497 76.4433 104.63 74.29C105.877 72.0233 106.5 68.68 106.5 64.26C106.5 60.9733 106.103 57.6867 105.31 54.4C104.517 51.1133 103.27 46.92 101.57 41.82C100.097 36.9467 99.02 33.32 98.34 30.94C97.7733 28.4467 97.49 26.0667 97.49 23.8C97.49 18.4733 99.19 14.0533 102.59 10.54C106.103 7.02667 110.183 5.27 114.83 5.27C121.517 5.27 127.127 8.10334 131.66 13.77C136.307 19.3233 139.763 26.18 142.03 34.34C144.297 42.5 145.43 50.32 145.43 57.8C145.43 70.2667 143.617 81.09 139.99 90.27C136.363 99.45 131.15 106.533 124.35 111.52C117.663 116.393 109.787 118.83 100.72 118.83C95.7333 118.83 90.86 117.697 86.1 115.43C81.34 113.163 76.92 109.763 72.84 105.23C68.76 109.763 64.2833 113.22 59.41 115.6C54.65 117.867 49.8333 119 44.96 119Z"
        fill="url(#paint0_linear_770_1005)"
      />
      <path
        d="M181.26 119C172.42 119 165.167 117.017 159.5 113.05C153.833 108.97 151 103.133 151 95.54C151 88.9667 153.89 83.7533 159.67 79.9C165.45 76.0467 172.647 74.12 181.26 74.12C184.434 74.12 187.38 74.4033 190.1 74.97C192.933 75.4233 194.86 75.99 195.88 76.67C195.88 73.8367 194.69 71.5133 192.31 69.7C189.93 67.7733 186.303 66.81 181.43 66.81C179.277 66.81 177.52 66.98 176.16 67.32C174.8 67.66 173.327 68.17 171.74 68.85C170.153 69.53 168.68 70.04 167.32 70.38C166.073 70.72 164.373 70.89 162.22 70.89C158.593 70.89 155.76 69.9833 153.72 68.17C151.68 66.2433 150.66 63.3533 150.66 59.5C150.66 55.08 152.247 51.34 155.42 48.28C158.707 45.22 162.787 42.9533 167.66 41.48C172.533 39.8933 177.407 39.1 182.28 39.1C191.913 39.1 199.96 40.9133 206.42 44.54C212.993 48.0533 217.81 52.7567 220.87 58.65C224.044 64.5433 225.63 71.06 225.63 78.2C225.63 82.0533 225.29 85.6233 224.61 88.91C224.043 92.1967 223.25 94.7467 222.23 96.56C223.817 97.92 225.007 99.5067 225.8 101.32C226.707 103.133 227.16 105.003 227.16 106.93C227.16 110.217 226.027 113.05 223.76 115.43C221.607 117.81 218.717 119 215.09 119C212.37 119 210.16 118.603 208.46 117.81C206.76 117.017 204.947 115.77 203.02 114.07C200.187 115.77 197.07 117.017 193.67 117.81C190.383 118.603 186.247 119 181.26 119ZM184.66 101.83C187.04 101.83 188.91 101.32 190.27 100.3C191.743 99.28 192.48 98.0333 192.48 96.56C192.48 95.0867 191.743 93.84 190.27 92.82C188.91 91.8 187.04 91.29 184.66 91.29C182.28 91.29 180.353 91.8 178.88 92.82C177.52 93.84 176.84 95.0867 176.84 96.56C176.84 98.0333 177.52 99.28 178.88 100.3C180.353 101.32 182.28 101.83 184.66 101.83Z"
        fill="white"
      />
      <path
        d="M181.26 119C172.42 119 165.167 117.017 159.5 113.05C153.833 108.97 151 103.133 151 95.54C151 88.9667 153.89 83.7533 159.67 79.9C165.45 76.0467 172.647 74.12 181.26 74.12C184.434 74.12 187.38 74.4033 190.1 74.97C192.933 75.4233 194.86 75.99 195.88 76.67C195.88 73.8367 194.69 71.5133 192.31 69.7C189.93 67.7733 186.303 66.81 181.43 66.81C179.277 66.81 177.52 66.98 176.16 67.32C174.8 67.66 173.327 68.17 171.74 68.85C170.153 69.53 168.68 70.04 167.32 70.38C166.073 70.72 164.373 70.89 162.22 70.89C158.593 70.89 155.76 69.9833 153.72 68.17C151.68 66.2433 150.66 63.3533 150.66 59.5C150.66 55.08 152.247 51.34 155.42 48.28C158.707 45.22 162.787 42.9533 167.66 41.48C172.533 39.8933 177.407 39.1 182.28 39.1C191.913 39.1 199.96 40.9133 206.42 44.54C212.993 48.0533 217.81 52.7567 220.87 58.65C224.044 64.5433 225.63 71.06 225.63 78.2C225.63 82.0533 225.29 85.6233 224.61 88.91C224.043 92.1967 223.25 94.7467 222.23 96.56C223.817 97.92 225.007 99.5067 225.8 101.32C226.707 103.133 227.16 105.003 227.16 106.93C227.16 110.217 226.027 113.05 223.76 115.43C221.607 117.81 218.717 119 215.09 119C212.37 119 210.16 118.603 208.46 117.81C206.76 117.017 204.947 115.77 203.02 114.07C200.187 115.77 197.07 117.017 193.67 117.81C190.383 118.603 186.247 119 181.26 119ZM184.66 101.83C187.04 101.83 188.91 101.32 190.27 100.3C191.743 99.28 192.48 98.0333 192.48 96.56C192.48 95.0867 191.743 93.84 190.27 92.82C188.91 91.8 187.04 91.29 184.66 91.29C182.28 91.29 180.353 91.8 178.88 92.82C177.52 93.84 176.84 95.0867 176.84 96.56C176.84 98.0333 177.52 99.28 178.88 100.3C180.353 101.32 182.28 101.83 184.66 101.83Z"
        fill="url(#paint1_linear_770_1005)"
      />
      <path
        d="M255.55 118.66C249.883 118.66 245.633 116.563 242.8 112.37C239.967 108.177 238.04 102 237.02 93.84C236.113 85.5667 235.66 74.0633 235.66 59.33C235.66 45.1633 236 34.1133 236.68 26.18C237.473 18.2467 239.287 11.9 242.12 7.14C245.067 2.38 249.6 0 255.72 0C261.84 0 266.317 2.38 269.15 7.14C272.097 11.9 273.91 18.2467 274.59 26.18C275.383 34.1133 275.78 45.1633 275.78 59.33C275.78 73.27 275.157 84.5467 273.91 93.16C272.777 101.66 270.737 108.063 267.79 112.37C264.844 116.563 260.764 118.66 255.55 118.66Z"
        fill="white"
      />
      <path
        d="M255.55 118.66C249.883 118.66 245.633 116.563 242.8 112.37C239.967 108.177 238.04 102 237.02 93.84C236.113 85.5667 235.66 74.0633 235.66 59.33C235.66 45.1633 236 34.1133 236.68 26.18C237.473 18.2467 239.287 11.9 242.12 7.14C245.067 2.38 249.6 0 255.72 0C261.84 0 266.317 2.38 269.15 7.14C272.097 11.9 273.91 18.2467 274.59 26.18C275.383 34.1133 275.78 45.1633 275.78 59.33C275.78 73.27 275.157 84.5467 273.91 93.16C272.777 101.66 270.737 108.063 267.79 112.37C264.844 116.563 260.764 118.66 255.55 118.66Z"
        fill="url(#paint2_linear_770_1005)"
      />
      <path
        d="M304.193 118.66C298.526 118.66 294.276 116.563 291.443 112.37C288.609 108.177 286.683 102 285.663 93.84C284.756 85.5667 284.303 74.0633 284.303 59.33C284.303 45.1633 284.643 34.1133 285.323 26.18C286.116 18.2467 287.929 11.9 290.763 7.14C293.709 2.38 298.243 0 304.363 0C310.483 0 314.959 2.38 317.793 7.14C320.739 11.9 322.553 18.2467 323.233 26.18C324.026 34.1133 324.423 45.1633 324.423 59.33C324.423 73.27 323.799 84.5467 322.553 93.16C321.419 101.66 319.379 108.063 316.433 112.37C313.486 116.563 309.406 118.66 304.193 118.66Z"
        fill="white"
      />
      <path
        d="M304.193 118.66C298.526 118.66 294.276 116.563 291.443 112.37C288.609 108.177 286.683 102 285.663 93.84C284.756 85.5667 284.303 74.0633 284.303 59.33C284.303 45.1633 284.643 34.1133 285.323 26.18C286.116 18.2467 287.929 11.9 290.763 7.14C293.709 2.38 298.243 0 304.363 0C310.483 0 314.959 2.38 317.793 7.14C320.739 11.9 322.553 18.2467 323.233 26.18C324.026 34.1133 324.423 45.1633 324.423 59.33C324.423 73.27 323.799 84.5467 322.553 93.16C321.419 101.66 319.379 108.063 316.433 112.37C313.486 116.563 309.406 118.66 304.193 118.66Z"
        fill="url(#paint3_linear_770_1005)"
      />
      <path
        d="M361.335 144.16C355.215 144.16 350.115 142.97 346.035 140.59C341.955 138.21 339.915 134.697 339.915 130.05C339.915 126.31 341.672 123.363 345.185 121.21C348.699 119.057 352.835 118.093 357.595 118.32C362.582 118.547 366.435 118.32 369.155 117.64C371.989 116.96 374.142 115.713 375.615 113.9C365.189 113.9 356.859 112.427 350.625 109.48C344.392 106.42 339.859 101.717 337.025 95.37C334.305 88.91 332.945 80.41 332.945 69.87C332.945 60.8033 334.305 53.4933 337.025 47.94C339.859 42.2733 344.279 39.44 350.285 39.44C355.612 39.44 359.522 40.97 362.015 44.03C364.622 46.9767 365.925 50.83 365.925 55.59L365.755 63.07C365.642 64.5433 365.585 66.6967 365.585 69.53C365.585 74.97 366.265 79.1067 367.625 81.94C368.985 84.66 371.705 86.02 375.785 86.02C379.865 86.02 382.585 84.66 383.945 81.94C385.305 79.1067 385.985 75.0267 385.985 69.7C385.985 66.8667 385.929 64.7133 385.815 63.24L385.645 55.93C385.645 50.83 386.892 46.8067 389.385 43.86C391.992 40.9133 395.959 39.44 401.285 39.44C407.292 39.44 411.712 42.2733 414.545 47.94C417.379 53.6067 418.795 60.9167 418.795 69.87C418.795 83.2433 416.869 95.54 413.015 106.76C409.275 117.867 403.099 126.877 394.485 133.79C385.985 140.703 374.935 144.16 361.335 144.16Z"
        fill="white"
      />
      <path
        d="M361.335 144.16C355.215 144.16 350.115 142.97 346.035 140.59C341.955 138.21 339.915 134.697 339.915 130.05C339.915 126.31 341.672 123.363 345.185 121.21C348.699 119.057 352.835 118.093 357.595 118.32C362.582 118.547 366.435 118.32 369.155 117.64C371.989 116.96 374.142 115.713 375.615 113.9C365.189 113.9 356.859 112.427 350.625 109.48C344.392 106.42 339.859 101.717 337.025 95.37C334.305 88.91 332.945 80.41 332.945 69.87C332.945 60.8033 334.305 53.4933 337.025 47.94C339.859 42.2733 344.279 39.44 350.285 39.44C355.612 39.44 359.522 40.97 362.015 44.03C364.622 46.9767 365.925 50.83 365.925 55.59L365.755 63.07C365.642 64.5433 365.585 66.6967 365.585 69.53C365.585 74.97 366.265 79.1067 367.625 81.94C368.985 84.66 371.705 86.02 375.785 86.02C379.865 86.02 382.585 84.66 383.945 81.94C385.305 79.1067 385.985 75.0267 385.985 69.7C385.985 66.8667 385.929 64.7133 385.815 63.24L385.645 55.93C385.645 50.83 386.892 46.8067 389.385 43.86C391.992 40.9133 395.959 39.44 401.285 39.44C407.292 39.44 411.712 42.2733 414.545 47.94C417.379 53.6067 418.795 60.9167 418.795 69.87C418.795 83.2433 416.869 95.54 413.015 106.76C409.275 117.867 403.099 126.877 394.485 133.79C385.985 140.703 374.935 144.16 361.335 144.16Z"
        fill="url(#paint4_linear_770_1005)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_770_1005"
          x1="-99.1917"
          y1="-87.8221"
          x2="428.213"
          y2="-163.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_770_1005"
          x1="-99.1917"
          y1="-87.8221"
          x2="428.213"
          y2="-163.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_770_1005"
          x1="-99.1917"
          y1="-87.8221"
          x2="428.213"
          y2="-163.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_770_1005"
          x1="-99.1917"
          y1="-87.8221"
          x2="428.213"
          y2="-163.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_770_1005"
          x1="-99.1917"
          y1="-87.8221"
          x2="428.213"
          y2="-163.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32ADF0" />
          <stop offset="1" stopColor="#FF00FE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
